.headText {
  margin-top:120px;
  margin-bottom: 40px;
  font-size: 26px;
  color: white;
  font-weight: 800;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.8);
}

.headText span{
  font-weight: 300
}

.subHeadText {
  font-weight: normal;
  margin-bottom: 20px;
  color: silver;
}

.loginWindow {
  width: 400px;
  margin: 0 auto;
  display: inline-block;
  background-color: white;
  min-height: 400px;
  padding:20px;
  box-shadow: 2x 2px 5px rgba(0,0,0,0.9);
}

.expiredMask {
  display: inline-block;
  width: 280px;
  height: 280px;
  background-color: gray;
  font-size: 30px;
  padding: 100px 0;
  margin:0 auto;
  color: white;
  cursor: pointer;
}