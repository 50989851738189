body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* color */
.red {
  color: red;
}
.gray {
  color: gray;
}
.silver {
  color: silver;
}

.bold {
  font-weight: bold
}

.fixed-width-fonts {
  font-family: Consolas, Monaco, monospace;
}

.margin5{
  margin: 5px;
}
.margin10{
  margin: 10px;
}
.margin20{
  margin: 20px;
}

.padding5 {
  padding: 5px;
}
.padding10 {
  padding: 10px;
}
.padding20 {
  padding: 20px;
}

.bgWhite{
  background-color: white;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.width-full{
  width: 100%;
}


.font14{
  font-size: 14px;
}
.font30{
  font-size: 30px;
}

.inline-block{
  display: inline-block;
}

.row{
  display: inline-block;
}

.pointer{
  cursor: pointer;
}

.max-width{
  max-width: 800px;
}

.ant-upload-picture-card-wrapper{
  display: inline !important;
}
.ant-upload-list-item-actions .anticon-eye-o{
  display: none;
}